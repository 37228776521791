import { useState } from 'react'
import Navbar from '../components/Navbar'
import Slider from '../components/Slider'
import appleIcon from '../assets/images/apple-transparent-icon.png'
import playstoreIcon from '../assets/images/android-transparent-icon.png'
import spin from '../assets/images/spin_button.png'
import androidVideo from '../assets/videos/android.mp4'
import ios from '../assets/videos/ios.mp4'

const html = `
    
    <div id="wrapper" className="clearfix">
        <header id="header" className="header dark header-size-lg">
            <div id="header-wrap">
                <div className="container">
                    <div className="header-row justify-content-lg-between">
                        <div id="logo" className="order-lg-3 col-auto px-0 m-auto">
                            <a href="/" className="standard-logo" data-dark-logo="images/logo-dark.png"><img src="images/logo.png" alt="MaltCasino Logo"></a>
                            <a href="/" className="retina-logo" data-dark-logo="images/logo-dark@2x.png"><img src="images/logo@2x.png" alt="MaltCasino Logo"></a>
                        </div>
                        <nav className="primary-menu style-5 order-lg-1 col-lg-4 px-0" style="position:inherit; border:none;">
                            <ul className="menu-container justify-content-lg-end" style="border:none; padding-right: 0px; margin-right: 0px;">
                                <li className="menu-item">
                                    <a href="#appstore-slider" className="button button-border button-circle button-fill fill-from-top button-amber"><i className="icon-appstore"></i><span>IOS İçin İndir</span></a>
                                </li>
                            </ul>
                        </nav>
                        <nav className="primary-menu style-5 order-lg-3 col-lg-4 px-0" style="position:inherit; border:none;">
                            <ul className="menu-container justify-content-lg-start" style="border:none; padding-right: 0px; margin-right: 0px;">
                                <li className="menu-item">
                                    <a href="#google-slider" className="button button-border button-circle button-fill fill-from-top button-amber"><i className="icon-google-play"></i><span>Android İçin İndir</span></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="header-wrap-clone"></div>
            <div className="d-flex justify-content-center d-lg-none">
                <a href="#appstore-slider" className="button button-border button-circle button-fill fill-from-top button-amber"><i className="icon-appstore"></i><span>IOS İçin İndir</span></a>
                <a href="#google-slider" className="button button-border button-circle button-fill fill-from-top button-amber"><i className="icon-google-play"></i><span>Android İçin İndir</span></a>
            </div>
        </header>
        <section id="page-title" className="page-title-parallax page-title-dark skrollable skrollable-between p-0">
            <img src="images/_slider.png" alt="Image" width="100%">
        </section>
        <section id="content">
            <div className="content-wrap overflow-visible py-0  mt-5" style="position: relative; transform: translateY(0px); z-index: 4;">
                <div className="container dark clearfix">
                    <div className="mx-auto center clearfix">
                        <img className="bottommargin" src="images/_mockup.png" alt="Image">
                        <h1><span>YÜZLERCE</span> OYUN, <span>BİNLERCE</span> SPOR MÜSABAKASI <br>VE <span>CANLI</span> BAHİSLER</h1>
                        <h3 style="font-weight: 400;">En yeni ve güncel oyunlar, spor müsabakaları ve yüksek oranlı bahisler, casino ve slotlar... Ödüllü turnuvalar, sana özel bonuslar ve çok daha fazlası <span style="font-weight: 600;">MALTCASINO</span>'da seni bekliyor. <span style="font-weight: 600;">MALTCASINO</span>'ya hemen üye ol ve ilk bonusunu kazan! Uygulamayı cihazına hemen yükle bahis keyfine başla!</h3>
                    </div>
                    <div className="line"></div>
                    <div className="row justify-content-center col-mb-50" data-animate="bounceInUp">
                        <div className="col-sm-6 col-lg-3 px-4">
                            <div className="feature-box fbox-lg fbox-center fbox-dark fbox-effect fbox-outline">
                                <div className="fbox-icon">
                                    <a><i className="i-alt border-0" style="line-height: 4.8rem;"><svg id="Layer_2" style="width: 70px; height: 70px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                                                <defs>
                                                    <style>.cls-1{fill:none;}.cls-1,.cls-2{stroke-width:0px;}.cls-2{fill:#fff;}</style>
                                                </defs>
                                                <g id="Layer_1-2">
                                                    <rect className="cls-1" width="100" height="100" rx="12.72" ry="12.72" />
                                                    <path className="cls-2" d="M63.15,28.64c.14,0,.28-.02.42-.06l8.26-2.47c.65.8,1.63,1.32,2.74,1.32,1.96,0,3.55-1.59,3.55-3.55s-1.59-3.55-3.55-3.55c-1.76,0-3.22,1.29-3.49,2.98l-8.33,2.49c-.77.23-1.2,1.04-.97,1.8.19.63.76,1.03,1.39,1.03ZM74.56,23.22c.36,0,.65.29.65.65s-.29.65-.65.65-.65-.29-.65-.65.29-.65.65-.65Z" />
                                                    <path className="cls-2" d="M13.47,49.51c2.44,0,4.42-1.98,4.42-4.42,0-.12-.03-.22-.03-.34l24.62-7.31v16.41c0,.4.17.79.47,1.06.14.13,1.45,1.29,3.66,1.89.72.19,1.58.33,2.55.33,1.82,0,4.04-.49,6.5-2.07.42-.27.67-.73.67-1.22v-16.62l28.22,7.57c0,.1-.03.19-.03.28,0,2.44,1.98,4.42,4.42,4.42s4.42-1.98,4.42-4.42-1.98-4.42-4.42-4.42c-1.23,0-2.35.51-3.15,1.33l-24.84-11.99-5.12-9.62,5.19-9.98s.01-.04.02-.05c.02-.05.04-.09.06-.14.01-.04.03-.08.04-.13.01-.05.02-.1.03-.15,0-.04.01-.09.01-.13,0-.05,0-.1,0-.15,0-.04,0-.09-.01-.13,0-.05-.02-.1-.03-.15,0-.03,0-.06-.02-.09,0,0,0-.01-.01-.02-.04-.11-.09-.22-.15-.32-.03-.04-.06-.08-.1-.12-.01-.02-.03-.03-.04-.05-.12-.13-.25-.23-.4-.31-.01,0-.02-.02-.03-.02-.23-.11-5.65-2.76-11.2-2.76s-10.76,2.66-10.98,2.77c-.34.18-.6.49-.72.86-.11.37-.08.77.11,1.11l5.33,9.96-5.52,9.66-20.88,11.88c-.8-.77-1.88-1.25-3.07-1.25-2.44,0-4.42,1.98-4.42,4.42s1.98,4.42,4.42,4.42ZM88.94,43.56c.84,0,1.53.69,1.53,1.53s-.69,1.53-1.53,1.53-1.53-.69-1.53-1.53.69-1.53,1.53-1.53ZM53.43,53.04c-4.01,2.23-6.96.83-8.06.1v-16.55l4.05-1.2,4.01,1.08v16.58ZM40.88,10.42c1.76-.72,5-1.84,8.31-1.84,2.56,0,5.14.67,7.04,1.31-5.4,2.03-11.07.61-11.14.59-.77-.2-1.56.26-1.77,1.03-.2.77.26,1.56,1.03,1.77.18.05,2.4.62,5.46.62,1.98,0,4.31-.24,6.66-1.01l-3.29,6.33c-3.5,1.2-6.5.4-7.57.03l-4.73-8.83ZM39.19,32.36s.05-.03.07-.05c.04-.03.09-.06.13-.09.04-.03.07-.06.1-.1s.07-.07.1-.1c.03-.04.06-.08.09-.13.02-.02.04-.05.05-.07l5.51-9.65c.95.28,2.37.59,4.11.59,1.25,0,2.65-.17,4.14-.61l5.13,9.63s0,.01.01.02c.06.1.13.2.21.29.02.02.04.04.06.06.06.06.13.12.21.16.03.02.06.04.09.06.02.01.04.03.06.04l12.21,5.9-21.71-5.82c-.26-.07-.53-.07-.79.01l-20.96,6.22,11.16-6.35ZM13.47,43.56c.84,0,1.53.69,1.53,1.53s-.69,1.53-1.53,1.53-1.53-.69-1.53-1.53.69-1.53,1.53-1.53Z" />
                                                    <path className="cls-2" d="M24.21,27.42c1.11,0,2.08-.52,2.74-1.32l8.5,2.54c.14.04.28.06.42.06.62,0,1.2-.41,1.39-1.03.23-.77-.21-1.57-.97-1.8l-8.57-2.57c-.27-1.69-1.73-2.98-3.49-2.98-1.96,0-3.55,1.59-3.55,3.55s1.59,3.55,3.55,3.55ZM24.21,23.22c.36,0,.65.29.65.65s-.29.65-.65.65-.65-.29-.65-.65.29-.65.65-.65Z" />
                                                    <path className="cls-2" d="M19.39,78.42c8.2,3.32,19.07,5.15,30.61,5.15s22.41-1.83,30.61-5.15c8.62-3.48,13.36-8.28,13.36-13.51,0-4.06-2.85-7.87-8.24-11.02-4.96-2.9-11.81-5.12-19.82-6.4-.67-.11-4.07-.63-5.08-.63-.8,0-1.45.65-1.45,1.45s.65,1.45,1.45,1.45c.57,0,3.05.35,4.62.6,4.1.66,7.86,1.57,11.2,2.69l-3.22.93c-3.29-1.21-7.37-2.15-11.99-2.75-.79-.1-1.52.46-1.62,1.25-.1.79.46,1.52,1.25,1.62,4.94.63,8.88,1.62,11.81,2.75.02,0,.04.02.06.02,4.04,1.56,6.13,3.4,6.13,4.96,0,1.87-3.04,4.01-7.94,5.6-5.63,1.82-13.13,2.82-21.14,2.82s-15.52-1-21.14-2.82c-4.9-1.58-7.94-3.73-7.94-5.6,0-2.62,5.97-6.09,17.39-7.65.79-.11,1.35-.84,1.24-1.63-.11-.79-.84-1.35-1.63-1.24-4.52.62-8.38,1.53-11.46,2.68l-3.19-.93c3.09-1.04,6.59-1.91,10.41-2.57.04,0,3.69-.61,5.12-.75.8-.08,1.38-.79,1.3-1.58-.08-.8-.79-1.38-1.58-1.3-1.53.15-5.15.75-5.32.77-7.78,1.33-14.42,3.55-19.21,6.42-5.21,3.12-7.97,6.88-7.97,10.86,0,5.22,4.74,10.02,13.36,13.51ZM16.1,73.64l11.74-3.51s.08.03.12.04c3.52,1.14,7.73,1.96,12.31,2.45l-4.92,6.99c-5.53-.83-10.6-2.14-14.89-3.87-1.62-.65-3.07-1.36-4.37-2.09ZM79.53,75.74c-4.29,1.74-9.37,3.04-14.91,3.88l-5.05-6.97c4.64-.48,8.91-1.32,12.47-2.47.08-.03.16-.05.24-.08l11.83,3.44c-1.35.78-2.87,1.52-4.58,2.21ZM77.47,55.82l3.63-1.05c1.13.51,2.2,1.05,3.18,1.62,2.49,1.46,4.11,2.89,5.14,4.21l-7.66-.19c-.61-1.95-2.39-3.48-4.29-4.59ZM81.74,63.3l9.17.23c.12.5.18.96.18,1.38,0,2.22-1.36,4.45-3.87,6.5l-10.64-3.09c3.25-1.68,4.67-3.47,5.17-5.02ZM56.2,72.93l5.15,7.11c-3.65.41-7.45.63-11.35.63s-7.74-.22-11.4-.64l5.01-7.12c2.08.14,4.22.22,6.39.22s4.18-.07,6.2-.2ZM18.85,54.78l3.64,1.06c-2.38,1.42-3.86,3.05-4.3,4.84l-7.67.03c1.64-2.16,4.51-4.19,8.34-5.92ZM9.09,63.56l9.25-.06c.56,1.53,2.02,3.26,5.22,4.88l-10.51,3.14s-.05.02-.07.03c-2.63-2.1-4.06-4.38-4.06-6.65,0-.45.06-.9.17-1.35Z" />
                                                    <path className="cls-2" d="M94.74,80.74c-.46-.65-1.36-.81-2.02-.35-9.8,6.9-26.22,11.03-43.9,11.03-16.38,0-31.55-3.48-41.62-9.54-.68-.41-1.57-.19-1.99.49-.41.68-.19,1.57.49,1.99,10.51,6.33,26.23,9.96,43.12,9.96,18.26,0,35.3-4.32,45.57-11.55.65-.46.81-1.36.35-2.02Z" />
                                                </g>
                                            </svg></i></a>
                                </div>
                                <div className="fbox-content">
                                    <h3>Oyunlar<span className="subtitle">En güncel oyunlar ve slotlar ile kazanmanın keyfini 2’ye katlayın!</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3 px-4">
                            <div className="feature-box fbox-lg fbox-center fbox-dark fbox-effect fbox-outline">
                                <div className="fbox-icon">
                                    <a><i className="i-alt border-0" style="line-height: 4.8rem;">
                                            <svg id="Layer_2" style="width: 70px; height: 70px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                                                <defs>
                                                    <style>.cls-1{fill:none;}.cls-1,.cls-2{stroke-width:0px;}.cls-2{fill:#fff;}</style>
                                                </defs>
                                                <g id="Layer_1-2">
                                                    <path className="cls-2" d="M33.43,37.5l16.54.05h0l16.59-.05c5.76-.03,10.49-4.73,10.54-10.49.03-3.85-2.03-7.42-5.38-9.31l-.07-.04c-2.48-1.4-5.36-1.74-8.1-.96-1.05.3-2.03.75-2.92,1.34-.04-.72-.14-1.44-.33-2.15-.71-2.76-2.46-5.07-4.93-6.5l-.07-.04c-3.33-1.94-7.45-1.91-10.75.06-3.17,1.89-4.98,5.22-5.12,8.68-.91-.61-1.91-1.08-3-1.39-2.74-.78-5.62-.44-8.1.96l-.07.04c-3.35,1.89-5.41,5.46-5.38,9.31.05,5.76,4.78,10.47,10.54,10.49ZM64.33,19.4c.7-.2,1.42-.3,2.14-.3,1.32,0,2.62.34,3.8,1.01l.07.04c2.46,1.39,3.97,4.01,3.95,6.83-.04,4.23-3.51,7.68-7.73,7.7l-14.05.05,6.64-10.76s0-.01.01-.02c.03-.04.05-.09.07-.13l.39-.63s.02-.04.04-.06c1.01-1.83,2.67-3.15,4.68-3.73ZM46.01,11.82c2.42-1.45,5.45-1.47,7.89-.05l.07.04c1.81,1.05,3.09,2.74,3.61,4.77.51,1.98.23,4.03-.78,5.8l-6.81,11.06-6.72-11.07c-2.11-3.65-.88-8.39,2.74-10.55ZM29.66,20.15l.07-.04c1.18-.67,2.49-1.01,3.81-1.01.71,0,1.43.1,2.14.3,2.01.57,3.67,1.89,4.68,3.73.01.02.02.04.03.06l7.07,11.54-14.01-.05c-4.23-.02-7.69-3.47-7.73-7.7-.02-2.83,1.49-5.44,3.95-6.83Z" />
                                                    <path className="cls-2" d="M87.54,38.44c-.32-1.39-2.21-1.91-3.18-2.16-1.47-.39-3.58-.75-6.26-1.06-.77-.09-1.47.46-1.56,1.24-.09.77.46,1.47,1.23,1.56,2.48.29,4.13.58,5.22.83-1.58.36-4.33.81-9.05,1.22-6.59.57-15.09.88-23.94.88s-17.35-.31-23.94-.88c-4.72-.41-7.47-.86-9.05-1.22,1.13-.25,2.86-.56,5.49-.86.77-.09,1.33-.79,1.24-1.56-.09-.77-.79-1.33-1.56-1.24-2.77.32-4.94.68-6.46,1.07-.99.26-2.93.78-3.26,2.18-.04.13-.07.27-.07.41v10.87c0,1.61,1.89,2.27,3.94,2.74.24.05.49.11.75.16-.09.18-.14.39-.14.61v33.96c0,1.8,2.39,2.43,3.54,2.73,1.66.43,4.01.82,6.98,1.15,6.03.67,14.03,1.03,22.53,1.03s16.51-.37,22.53-1.03c2.97-.33,5.32-.72,6.98-1.15,1.15-.3,3.54-.93,3.54-2.73v-33.96c0-.22-.05-.42-.14-.61.26-.05.51-.11.75-.16,2.05-.47,3.94-1.12,3.94-2.74v-10.87c0-.14-.03-.28-.07-.4ZM80.24,53.22v33.49c-1.9.97-11.92,2.57-30.24,2.57s-28.34-1.6-30.24-2.57v-33.49c0-.05,0-.1-.02-.16,1.32.19,2.83.37,4.52.53,6.88.67,16.02,1.03,25.73,1.03s18.85-.37,25.73-1.03c1.69-.16,3.2-.34,4.53-.53,0,.05-.02.1-.02.16ZM73.94,50.93c-6.59.57-15.09.88-23.94.88s-17.35-.31-23.94-.88c-7.67-.66-10.13-1.44-10.85-1.75v-7.89c.36.11.74.21,1.12.29,1.88.43,4.55.82,7.94,1.14,6.88.67,16.02,1.03,25.73,1.03s18.85-.37,25.73-1.03c3.39-.33,6.05-.71,7.94-1.14.39-.09.76-.19,1.12-.29v7.89c-.71.31-3.17,1.09-10.85,1.75Z" />
                                                    <rect className="cls-1" width="100" height="100" rx="12.72" ry="12.72" />
                                                </g>
                                            </svg>
                                        </i></a>
                                </div>
                                <div className="fbox-content">
                                    <h3>Ödül & Bonuslar<span className="subtitle">Size özel bonus ve ödüller ile kazançlarınız katlanıyor.</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3 px-4">
                            <div className="feature-box fbox-lg fbox-center fbox-dark fbox-effect fbox-outline">
                                <div className="fbox-icon">
                                    <a><i className="i-alt border-0" style="line-height: 4.8rem;">
                                            <svg id="Layer_2" style="width: 70px; height: 70px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                                                <defs>
                                                    <style>.cls-1{fill:none;}.cls-1,.cls-2{stroke-width:0px;}.cls-2{fill:#fff;}</style>
                                                </defs>
                                                <g id="Layer_1-2">
                                                    <rect className="cls-1" width="100" height="100" rx="12.72" ry="12.72" />
                                                    <path className="cls-2" d="M43.91,20.71l-2.47,7.6c-.18.56.02,1.18.5,1.53.48.35,1.13.35,1.6,0l6.46-4.7,6.47,4.7c.24.17.52.26.8.26s.56-.09.8-.26c.48-.35.68-.96.5-1.53l-2.47-7.6,6.47-4.7c.48-.35.68-.96.5-1.53-.18-.56-.71-.94-1.3-.94h-7.99l-2.47-7.6c-.18-.56-.71-.94-1.3-.94s-1.11.38-1.3.94l-2.47,7.6h-7.99c-.59,0-1.11.38-1.3.94-.18.56.02,1.18.49,1.53l6.47,4.7ZM47.22,16.27c.59,0,1.11-.38,1.3-.94l1.48-4.55,1.48,4.55c.18.56.71.94,1.3.94h4.79l-3.87,2.81c-.48.35-.68.96-.49,1.52l1.48,4.55-3.87-2.81c-.24-.17-.52-.26-.8-.26s-.56.09-.8.26l-3.87,2.81,1.48-4.55c.18-.56-.02-1.18-.5-1.52l-3.87-2.81h4.79Z" />
                                                    <path className="cls-2" d="M29.67,53.18l-2.47,7.6c-.18.56.02,1.18.49,1.53.48.35,1.13.35,1.6,0l6.46-4.7,6.47,4.7c.24.17.52.26.8.26s.56-.09.8-.26c.48-.35.68-.96.49-1.53l-2.47-7.6,6.46-4.7c.48-.35.68-.96.5-1.52-.18-.56-.71-.94-1.3-.94h-7.99l-2.47-7.6c-.18-.56-.71-.94-1.3-.94s-1.11.38-1.3.94l-2.47,7.6h-7.99c-.59,0-1.11.38-1.3.94-.18.56.02,1.18.49,1.52l6.47,4.7ZM32.99,48.74c.59,0,1.11-.38,1.3-.94l1.48-4.55,1.48,4.55c.18.56.71.94,1.3.94h4.79l-3.87,2.81c-.48.35-.68.96-.49,1.52l1.48,4.55-3.87-2.81c-.24-.17-.52-.26-.8-.26s-.56.09-.8.26l-3.87,2.81,1.48-4.55c.18-.56-.02-1.18-.5-1.52l-3.87-2.81h4.79Z" />
                                                    <path className="cls-2" d="M68,46l-2.47-7.6c-.18-.56-.71-.94-1.3-.94s-1.11.38-1.3.94l-2.47,7.6h-7.99c-.59,0-1.11.38-1.3.94-.18.56.02,1.18.49,1.52l6.47,4.7-2.47,7.6c-.18.56.02,1.18.5,1.53.48.35,1.13.35,1.6,0l6.47-4.7,6.47,4.7c.24.17.52.26.8.26s.56-.09.8-.26c.48-.35.68-.96.5-1.53l-2.47-7.6,6.47-4.7c.48-.35.68-.96.49-1.52-.18-.56-.71-.94-1.3-.94h-7.99ZM67.93,51.54c-.48.35-.68.96-.5,1.52l1.48,4.55-3.87-2.81c-.24-.17-.52-.26-.8-.26s-.56.09-.8.26l-3.87,2.81,1.48-4.55c.18-.56-.02-1.18-.5-1.52l-3.87-2.81h4.79c.59,0,1.11-.38,1.3-.94l1.48-4.55,1.48,4.55c.18.56.71.94,1.3.94h4.79l-3.87,2.81Z" />
                                                    <path className="cls-2" d="M33.28,78.45h-7.99l-2.47-7.6c-.18-.56-.71-.94-1.3-.94s-1.11.38-1.3.94l-2.47,7.6h-7.99c-.59,0-1.11.38-1.3.94-.18.56.02,1.18.49,1.52l6.47,4.7-2.47,7.6c-.18.56.02,1.18.49,1.52.48.35,1.13.35,1.6,0l6.46-4.7,6.47,4.7c.24.17.52.26.8.26s.56-.09.8-.26c.48-.35.68-.96.49-1.52l-2.47-7.6,6.46-4.7c.48-.35.68-.96.5-1.52-.18-.56-.71-.94-1.3-.94ZM25.22,83.99c-.48.35-.68.96-.49,1.52l1.48,4.55-3.87-2.81c-.24-.17-.52-.26-.8-.26s-.56.09-.8.26l-3.87,2.81,1.48-4.55c.18-.56-.02-1.18-.49-1.52l-3.87-2.81h4.79c.59,0,1.11-.38,1.3-.94l1.48-4.55,1.48,4.55c.18.56.71.94,1.3.94h4.79l-3.87,2.81Z" />
                                                    <path className="cls-2" d="M61.76,78.43h-7.99l-2.47-7.6c-.18-.56-.71-.94-1.3-.94s-1.11.38-1.3.94l-2.47,7.6h-7.99c-.59,0-1.11.38-1.3.94-.18.56.02,1.18.49,1.52l6.47,4.7-2.47,7.6c-.18.56.02,1.18.5,1.52.48.35,1.13.35,1.6,0l6.46-4.7,6.47,4.7c.24.17.52.26.8.26s.56-.09.8-.26c.48-.35.68-.96.5-1.52l-2.47-7.6,6.47-4.7c.48-.35.68-.96.5-1.52-.18-.56-.71-.94-1.3-.94ZM53.69,83.97c-.48.35-.68.96-.49,1.52l1.48,4.55-3.87-2.81c-.24-.17-.52-.26-.8-.26s-.56.09-.8.26l-3.87,2.81,1.48-4.55c.18-.56-.02-1.18-.5-1.52l-3.87-2.81h4.79c.59,0,1.11-.38,1.3-.94l1.48-4.55,1.48,4.55c.18.56.71.94,1.3.94h4.79l-3.87,2.81Z" />
                                                    <path className="cls-2" d="M91.53,79.36c-.18-.56-.71-.94-1.3-.94h-7.99l-2.47-7.6c-.18-.56-.71-.94-1.3-.94s-1.11.38-1.3.94l-2.47,7.6h-7.99c-.59,0-1.11.38-1.3.94-.18.56.02,1.18.49,1.52l6.47,4.7-2.47,7.6c-.18.56.02,1.18.5,1.52.48.35,1.12.35,1.6,0l6.47-4.7,6.47,4.7c.24.17.52.26.8.26s.56-.09.8-.26c.48-.35.68-.96.5-1.52l-2.47-7.6,6.47-4.7c.48-.35.68-.96.49-1.52ZM82.16,83.96c-.48.35-.68.96-.5,1.52l1.48,4.55-3.87-2.81c-.24-.17-.52-.26-.8-.26s-.56.09-.8.26l-3.87,2.81,1.48-4.55c.18-.56-.02-1.18-.5-1.52l-3.87-2.81h4.79c.59,0,1.11-.38,1.3-.94l1.48-4.55,1.48,4.55c.18.56.71.94,1.3.94h4.79l-3.87,2.81Z" />
                                                    <path className="cls-2" d="M13.25,67.6h73.5c.75,0,1.36-.61,1.36-1.36s-.61-1.36-1.36-1.36H13.25c-.75,0-1.36.61-1.36,1.36s.61,1.36,1.36,1.36Z" />
                                                    <path className="cls-2" d="M24.92,35.15h50.16c.75,0,1.36-.61,1.36-1.36s-.61-1.36-1.36-1.36H24.92c-.75,0-1.36.61-1.36,1.36s.61,1.36,1.36,1.36Z" />
                                                </g>
                                            </svg>
                                        </i></a>
                                </div>
                                <div className="fbox-content">
                                    <h3>Turnuva & Yarışmalar<span className="subtitle">Platform üzerinde özel turnuvalara katılarak hem heyecanı hem de kazancınızı artırın.</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3 px-4">
                            <div className="feature-box fbox-lg fbox-center fbox-dark fbox-effect fbox-outline">
                                <div className="fbox-icon">
                                    <a><i className="i-alt border-0" style="line-height: 4.8rem;">
                                            <svg id="Layer_2" style="width: 70px; height: 70px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                                                <defs>
                                                    <style>.cls-1{fill:none;}.cls-1,.cls-2{stroke-width:0px;}.cls-2{fill:#fff;}</style>
                                                </defs>
                                                <g id="Layer_1-2">
                                                    <rect className="cls-1" y="0" width="100" height="100" rx="12.72" ry="12.72" />
                                                    <path className="cls-2" d="M30.21,16.36c5.97-3.52,12.81-5.38,19.79-5.38,10.42,0,20.22,4.06,27.59,11.43,7.37,7.37,11.43,17.17,11.43,27.59,0,6.12-1.38,11.98-4.09,17.42-.31.61-.06,1.36.56,1.66.18.09.37.13.55.13.46,0,.89-.25,1.11-.69,2.89-5.78,4.36-12.02,4.36-18.53,0-11.09-4.32-21.51-12.16-29.34-7.84-7.84-18.26-12.16-29.35-12.16-7.41,0-14.69,1.98-21.04,5.72-.59.35-.79,1.11-.44,1.7.35.59,1.11.79,1.7.44Z" />
                                                    <path className="cls-2" d="M75.78,79.3c-7.12,6.27-16.27,9.73-25.78,9.73-10.42,0-20.22-4.06-27.59-11.43-7.37-7.37-11.43-17.17-11.43-27.59,0-6.69,1.72-13.28,4.97-19.08.34-.6.12-1.35-.47-1.69-.6-.34-1.35-.12-1.69.47-3.46,6.16-5.29,13.18-5.29,20.29,0,11.09,4.32,21.51,12.16,29.35,7.84,7.84,18.26,12.16,29.34,12.16,10.11,0,19.84-3.67,27.42-10.34.51-.45.56-1.24.11-1.75-.45-.51-1.24-.56-1.75-.11Z" />
                                                    <path className="cls-2" d="M18.4,46.17c-.68-.05-1.28.46-1.33,1.14-.07.87-.1,1.76-.1,2.64,0,7.57,2.49,14.7,7.21,20.6.24.31.6.47.97.47.27,0,.55-.09.77-.27.53-.43.62-1.21.2-1.74-4.36-5.46-6.67-12.05-6.67-19.05,0-.81.03-1.63.1-2.44.05-.68-.46-1.28-1.14-1.33Z" />
                                                    <path className="cls-2" d="M61.24,78.36c-3.57,1.41-7.36,2.13-11.24,2.13-3.62,0-7.17-.63-10.54-1.87-.64-.24-1.36.09-1.59.74-.24.64.09,1.35.74,1.59,3.65,1.34,7.48,2.02,11.4,2.02,4.19,0,8.28-.78,12.15-2.31.64-.25.95-.97.7-1.61-.25-.64-.97-.95-1.61-.7Z" />
                                                    <path className="cls-2" d="M74.4,70.37c.23.17.49.26.76.26.37,0,.74-.17.98-.48,4.51-5.83,6.9-12.82,6.9-20.21,0-.64-.02-1.29-.05-1.93-.04-.68-.63-1.21-1.31-1.17-.68.04-1.21.63-1.17,1.31.03.59.05,1.19.05,1.79,0,6.84-2.2,13.3-6.38,18.69-.42.54-.32,1.32.22,1.74Z" />
                                                    <path className="cls-2" d="M75.56,33.19c.24.36.63.56,1.04.56.23,0,.47-.07.68-.2.57-.38.73-1.14.36-1.72-4.29-6.54-10.77-11.35-18.23-13.57-.66-.19-1.35.18-1.54.84-.19.66.18,1.35.84,1.54,6.9,2.05,12.89,6.5,16.86,12.55Z" />
                                                    <path className="cls-2" d="M25.32,31.92c3.94-5.38,9.61-9.39,15.97-11.27.66-.19,1.03-.88.84-1.54-.2-.66-.88-1.03-1.54-.84-6.88,2.04-13.01,6.37-17.27,12.19-.4.55-.28,1.33.27,1.73.22.16.48.24.73.24.38,0,.76-.18,1-.51Z" />
                                                    <path className="cls-2" d="M82.99,38.64s0,0,0,0c0,0,0,0,0,0,0-.01-.01-.02-.02-.03-.05-.13-.11-.24-.2-.35-.03-.03-.06-.06-.09-.1-.08-.08-.16-.14-.25-.2-.03-.02-.06-.04-.1-.06-.12-.06-.25-.1-.39-.12l-21.91-2.52-8.91-19.87c-.06-.13-.14-.24-.24-.34-.03-.03-.05-.05-.08-.07-.08-.07-.17-.13-.27-.18-.04-.02-.07-.04-.11-.05-.14-.05-.28-.09-.43-.09s-.29.04-.43.09c-.04.02-.08.03-.12.05-.1.05-.18.11-.26.18-.03.03-.06.05-.09.08-.09.1-.17.21-.23.34v.02s-.01,0-.01,0l-8.89,19.84-21.91,2.52c-.14.02-.27.06-.39.12-.03.02-.06.04-.09.06-.1.06-.18.12-.26.2-.03.03-.06.06-.09.09-.08.1-.15.22-.2.35,0,.01-.01.02-.02.03,0,0,0,0,0,0,0,0,0,0,0,0-.04.14-.05.29-.05.43,0,.04,0,.08.02.12.02.11.05.21.09.31.02.04.03.07.05.11.07.12.15.24.26.33h0s0,0,0,0l16.37,14.41-4.62,21.76s0,0,0,0v.02c-.03.14-.03.28-.02.41,0,.04.02.08.03.12.02.1.06.2.11.3.02.04.04.08.06.11.08.12.17.22.28.31,0,0,0,.01.01.02,0,0,0,0,0,0,0,0,0,0,0,0,.12.09.26.14.4.18.04.01.08.02.12.02.11.02.21.02.32.01.04,0,.08,0,.12-.01.14-.03.27-.07.4-.14h0s0,0,0,0l19.02-11.3,19.02,11.31s.03,0,.04.02c.06.04.13.06.2.08.03.01.06.02.09.03.1.03.2.04.3.04,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,.17,0,.33-.05.49-.12.08-.03.16-.06.23-.12,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0-.01.01-.01.12-.09.21-.19.28-.31.02-.03.04-.07.06-.11.05-.1.09-.2.11-.31,0-.04.02-.07.02-.11.02-.14.02-.28-.01-.42v-.02s0,0,0,0l-4.62-21.76,16.37-14.41s0,0,0,0h0c.11-.1.19-.22.26-.34.02-.03.03-.07.05-.11.04-.1.07-.2.09-.31,0-.04.01-.08.02-.12,0-.14,0-.29-.05-.43ZM59.05,37.65l16.95,1.95-24.77,8.05v-25.94l6.83,15.22c.18.4.56.67.99.72ZM40.95,37.65c.43-.05.81-.33.99-.72l6.82-15.22v25.94l-24.76-8.05,16.95-1.95ZM35.94,52.98l-12.42-10.93,24.48,7.95-15.23,20.96,3.57-16.79c.09-.44-.06-.89-.39-1.19ZM50,63.48c-.22,0-.44.06-.63.17l-14.48,8.61,15.12-20.81,15.12,20.81-14.48-8.61c-.2-.12-.41-.17-.63-.17ZM63.67,54.17l3.57,16.79-15.23-20.96,24.48-7.95-12.42,10.93c-.34.3-.49.75-.39,1.19Z" />
                                                    <path className="cls-2" d="M84.87,14.41c.69,0,1.24-.55,1.24-1.24v-.68c0-.68-.55-1.24-1.24-1.24s-1.24.55-1.24,1.24v.68c0,.68.56,1.24,1.24,1.24Z" />
                                                    <path className="cls-2" d="M84.87,20.57c.69,0,1.24-.55,1.24-1.24v-.68c0-.68-.55-1.24-1.24-1.24s-1.24.55-1.24,1.24v.68c0,.68.56,1.24,1.24,1.24Z" />
                                                    <path className="cls-2" d="M81.45,17.15h.68c.68,0,1.24-.56,1.24-1.24s-.56-1.24-1.24-1.24h-.68c-.69,0-1.24.55-1.24,1.24s.55,1.24,1.24,1.24Z" />
                                                    <path className="cls-2" d="M87.61,17.15h.68c.68,0,1.24-.56,1.24-1.24s-.56-1.24-1.24-1.24h-.68c-.69,0-1.24.55-1.24,1.24s.55,1.24,1.24,1.24Z" />
                                                    <path className="cls-2" d="M15.98,80.5c-.69,0-1.24.55-1.24,1.24v.68c0,.68.55,1.24,1.24,1.24s1.24-.55,1.24-1.24v-.68c0-.68-.56-1.24-1.24-1.24Z" />
                                                    <path className="cls-2" d="M15.98,86.65c-.69,0-1.24.56-1.24,1.24v.68c0,.68.55,1.24,1.24,1.24s1.24-.55,1.24-1.24v-.68c0-.68-.56-1.24-1.24-1.24Z" />
                                                    <path className="cls-2" d="M13.24,83.92h-.68c-.68,0-1.24.56-1.24,1.24s.56,1.24,1.24,1.24h.68c.69,0,1.24-.55,1.24-1.24s-.55-1.24-1.24-1.24Z" />
                                                    <path className="cls-2" d="M19.4,83.92h-.68c-.68,0-1.24.56-1.24,1.24s.56,1.24,1.24,1.24h.68c.68,0,1.24-.55,1.24-1.24s-.56-1.24-1.24-1.24Z" />
                                                    <path className="cls-2" d="M9.4,20.65l6.7,1.93,1.93,6.7c.15.53.64.9,1.19.9s1.04-.37,1.19-.9l1.93-6.7,6.7-1.93c.53-.15.9-.64.9-1.19s-.37-1.04-.9-1.19l-6.7-1.93-1.93-6.7c-.15-.53-.64-.9-1.19-.9s-1.04.37-1.19.9l-1.93,6.7-6.7,1.93c-.53.15-.9.64-.9,1.19s.37,1.04.9,1.19ZM17.44,18.52c.41-.12.73-.44.85-.85l.93-3.23.93,3.23c.12.41.44.73.85.85l3.23.93-3.23.93c-.41.12-.73.44-.85.85l-.93,3.23-.93-3.23c-.12-.41-.44-.73-.85-.85l-3.23-.93,3.23-.93Z" />
                                                    <path className="cls-2" d="M88.58,74.4l-3.86-1.11-1.11-3.86c-.15-.53-.64-.9-1.19-.9s-1.04.37-1.19.9l-1.11,3.86-3.86,1.11c-.53.15-.9.64-.9,1.19s.37,1.04.9,1.19l3.86,1.11,1.11,3.86c.15.53.64.9,1.19.9s1.04-.37,1.19-.9l1.11-3.86,3.86-1.11c.53-.15.9-.64.9-1.19s-.37-1.04-.9-1.19ZM83.37,75.7c-.41.12-.73.44-.85.85l-.11.39-.11-.39c-.12-.41-.44-.73-.85-.85l-.39-.11.39-.11c.41-.12.73-.44.85-.85l.11-.39.11.39c.12.41.44.73.85.85l.39.11-.39.11Z" />
                                                </g>
                                            </svg>
                                        </i></a>
                                </div>
                                <div className="fbox-content">
                                    <h3>Üst Düzey Güvenlik<span className="subtitle">Dilediğiniz yerden dilediğiniz an gönül rahatlığıyla bahis yapın.</span></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="google-slider" className="clear"></div>
                <div className="section parallax dark mb-0 border-bottom-0" style="background-image: url('images/7.jpg'); background-repeat: repeat;" data-bottom-top="background-position-y:0px;" data-top-bottom="background-position-y:-300px;">
                    <div className="container clearfix">
                        <h1 className="center">MALTCASINO <span>ANDROİD TELEFONLARA</span><br>NASIL İNDİRİLİR?</h1>
                        <div id="oc-images" className="owl-carousel image-carousel carousel-widget" data-speed="1000" data-loop="true" data-items-xs="2" data-items-sm="2" data-items-lg="3" data-items-xl="5">
                            <div className="oc-item">
                                <img src="images/and01.jpg">
                            </div>
                            <div className="oc-item">
                                <img src="images/and02.jpg">
                            </div>
                            <div className="oc-item">
                                <img src="images/and03.jpg">
                            </div>
                            <div className="oc-item">
                                <img src="images/and04.jpg">
                            </div>
                            <div className="oc-item">
                                <img src="images/and05.jpg">
                            </div>
                        </div>
                    </div>
                </div>
                <div id="appstore-slider" className="clear"></div>
                <div className="section parallax dark my-0 border-bottom-0" style="background-image: url('images/7.jpg'); background-repeat: repeat;" data-bottom-top="background-position-y:0px;" data-top-bottom="background-position-y:-300px;">
                    <div className="container clearfix">
                        <h1 className="center">MALTCASINO <span>APPLE TELEFONLARA</span><br>NASIL İNDİRİLİR?</h1>
                        <div id="oc-images" className="owl-carousel image-carousel carousel-widget" data-speed="1000" data-loop="true" data-items-xs="2" data-items-sm="2" data-items-lg="3" data-items-xl="5">
                            <div className="oc-item">
                                <img src="images/ios01.jpg">
                            </div>
                            <div className="oc-item">
                                <img src="images/ios02.jpg">
                            </div>
                            <div className="oc-item">
                                <img src="images/ios03.jpg">
                            </div>
                            <div className="oc-item">
                                <img src="images/ios04.jpg">
                            </div>
                            <div className="oc-item">
                                <img src="images/ios05.jpg">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section my-0 clearfix" style="background-color: #ffb400;">
                    <div className="container clearfix">
                        <ul id="myTab2" className="nav nav-pills boot-tabs justify-content-center">
                            <li className="nav-item">
                                <a href="#iosvideo" data-bs-toggle="tab" className="button button-border button-circle button-fill fill-from-top button-dark active"><i className="icon-appstore"></i><span>IOS</span></a>
                            </li>
                            <li className="nav-item">
                                <a href="#androidvideo" data-bs-toggle="tab" className="button button-border button-circle button-fill fill-from-top button-dark"><i className="icon-google-play"></i><span>Android</span></a>
                            </li>
                        </ul>
                        <div id="myTabContent2" className="tab-content">
                            <div className="tab-pane fade active show" id="iosvideo">
                                <div className="row justify-content-center col-mb-50" data-animate="bounceInLeft">
                                    <div className="col-lg-8">
                                        <h1 className="center">MALTCASINO <span style="color: #15181E;">APPLE TELEFONLARA</span><br>NASIL İNDİRİLİR?</h1>
                                        <video poster="images/_iosthumb.png" preload="auto" controls="" style="display: block; width: 100%;">
                                            <source src="ios.mp4" type="video/mp4">
                                        </video>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="androidvideo">
                                <div className="row justify-content-center col-mb-50" data-animate="bounceInRight">
                                    <div className="col-lg-8">
                                        <h1 className="center">MALTCASINO <span style="color: #15181E;">ANDROID TELEFONLARA</span><br>NASIL İNDİRİLİR?</h1>
                                        <video poster="images/_androidthumb.png" preload="auto" controls="" style="display: block; width: 100%;">
                                            <source src="android.mp4" type="video/mp4">
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section border-top-0 m-0">
                    <div className="container clearfix">
                        <div className="row col-mb-0">
                            <div className="col-md-6 text-center text-md-start">
                                <div className="heading-block mt-0 mb-4">
                                    <h2>HEYECAN <span>MALTCASINO’DA</span></h2>
                                    <span>
                                        Neden MALTCASINO?<br>
                                        Yüksek bahis çeşitliliği<br>
                                        Yüzlerce güncel oyun<br>
                                        Kolay yatırma ve çekme işlemleri<br>
                                        7/24 Canlı destek<br>
                                        Yüksek güvenlik
                                    </span>
                                </div>
                                <div className="d-flex justify-content-center justify-content-md-start mb-4">
                                    <a href="https://x.com/ResmiMaltcasino" target="_blank" className="social-icon si-twitter">
                                        <i><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="fill: #FFFFFF; width: 25px;"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg></i>
                                        <i><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="fill: #FFFFFF; width: 25px;"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg></i>
                                    </a>
                                    <a href="https://www.facebook.com/Malt-Casino-111684408124292" target="_blank" className="social-icon si-facebook">
                                        <i className="icon-facebook"></i>
                                        <i className="icon-facebook"></i>
                                    </a>
                                    <a href="https://www.instagram.com/maltcasinotr/" target="_blank" className="social-icon si-instagram">
                                        <i className="icon-instagram"></i>
                                        <i className="icon-instagram"></i>
                                    </a>
                                    <a href="https://t.me/%20dGbK8IOf0kZjOTcx" target="_blank" className="social-icon si-linkedin">
                                        <i className="icon-telegram"></i>
                                        <i className="icon-telegram"></i>
                                    </a>
                                </div>
                                <p className="mb-0">© 2023 MALTCASINO</p>
                            </div>
                            <div className="col-md-6 text-center text-md-start">
                                <img src="images/_footer.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div id="gotoTop" className="icon-angle-up"></div>
    <script src="script.min.js"></script>
    <script>
    var tpj = jQuery;
    var $ = jQuery.noConflict();
    </script>
`

const Home = () => {
  
  const [activeTab, setActiveTab] = useState('android');
  const [videoSource, setVideoSource] = useState(androidVideo)


  const handler = (e) => {
    setActiveTab(activeTab === 'android' ? 'ios' : 'android')
    setVideoSource(videoSource === androidVideo ? ios : androidVideo)
  }

  return (
    <div  />
  )
}

export default Home

const Player = ({ key, source }) => {
  return (
    <video key={key} className="lg:w-3/4 border m-auto border-gray-200 rounded-lg dark:border-gray-700" controls>
      <source src={source} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  )
}

import { useState, useEffect } from 'react'
import axios from 'axios'
import qs from 'qs'

const Panel = () => {
  const [form , setForm] = useState({
    link: '',
    pass: ''
  })
  const [ message, setMessage ] = useState(null)

  const submitHandler = async (e) => {
    e.preventDefault();
    console.log('submit')
    console.log(form)
    const response = await axios.post('./update.php', form, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if(response.data.message){
      setMessage(response.data.message)
      setTimeout(() => {
        setMessage(null)
        setForm({
          link: '',
          pass: ''
        })
      }, 4000);
    }
    
  }
  return (
    <div className='w-screen h-screen bg-gray-100 flex justify-center items-center' >
      <div className="w-full max-w-xs">
        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <label className="flex flex-col text-gray-700 text-sm font-bold mb-2" htmlFor="url">
              Redirect Link <span className='text-xs text-gray-500' >exp: https://your-website312.com</span>
            </label>
            <input 
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
              id="link" 
              type="text" 
              placeholder="Redirect Link" 
              value={form.link}
              onChange={e => setForm({...form, link: e.target.value})}/>
              { message && <p className="text-green-500 text-xs italic">{message}</p>}
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>
            <input 
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" 
              id="password" 
              type="password" 
              placeholder="******************"
              value={form.pass}
              onChange={e => setForm({...form, pass: e.target.value})}/>
          </div>
          <div className='w-full flex justify-center' >
            <button 
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              onClick={e=> submitHandler(e)}>
              Change
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Panel